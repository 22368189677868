import React, {
	FC,
	MutableRefObject,
	useRef,
	useEffect,
	useState,
	useCallback,
	ComponentType,
} from "react";
import {
	AlertDialog,
	AlertDialogCloseButton,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
	Box,
	Button,
	FormControl,
	FormErrorMessage,
	FormHelperText,
	FormLabel,
	Heading,
	HStack,
	IconButton,
	Text,
	useDisclosure,
	useToast,
} from "@chakra-ui/react";
import { Control } from "react-hook-form";
import {
	AsyncCreatableSelect,
	AsyncSelect,
	chakraComponents,
	ChakraStylesConfig,
} from "chakra-react-select";
import {
	createSelectOption,
	deleteSelectOption,
	getSelectOptions,
} from "@api/select-options.api";
import { SelectValueTypes } from "@definitions/select-keys";
import { Controller } from "react-hook-form";
import convertDataToSelectOption from "@utils/convert-data-to-select-option";
import { FiTrash2 } from "react-icons/fi";
import getNestedValue from "@utils/get-nested-value";
import debounce from "@utils/debounce";
import useAuthContext from "@hooks/useAuthContext";
import { UserTypeEnum } from "@interfaces/api/auth.types";
import { Obj } from "@interfaces/types";
import DirtyFieldHelperText from "@components/form/dirty-field-helper-text/dirty-field-helper-text";

export interface IOption {
	label: string;
	value: string | number;
	data?: any;
}

export interface ICityOption extends IOption {
	country: string;
}

interface FormAsyncSelectProps {
	name: string;
	selector:
		| SelectValueTypes
		| ((input?: string, filters?: Obj) => Promise<{ items: any[] }>);
	selectorFilters?: Obj;
	onOptionDelete?: (id: number | string) => void;
	control: Control<any, any>;
	creatable?: boolean; //option will be created right away
	localCreatable?: boolean; //option will be created after the form will be posted
	label?: string;
	placeholder?: string;
	disabled?: boolean;
	isMulti?: boolean;
	isClearable?: boolean;
	defaultOptions?: boolean | any[];
	optionComponent?: ComponentType<any>;
	mappingKeys?: {
		labelKey?: string | string[];
		valueKey?: string | string[];
	};
	styles?: ChakraStylesConfig;
	shouldShowUnsavedWarning?: boolean;
	additionalOptions?: IOption[];
	required?: boolean;
}

const FormAsyncSelect: FC<FormAsyncSelectProps> = ({
	label,
	name,
	control,
	creatable,
	localCreatable,
	selector,
	selectorFilters,
	disabled,
	placeholder,
	isMulti,
	defaultOptions: defaultOptionsProps,
	optionComponent,
	onOptionDelete,
	mappingKeys,
	styles,
	isClearable,
	shouldShowUnsavedWarning,
	additionalOptions = [],
	required,
}) => {
	const toast = useToast();
	const { authData } = useAuthContext();
	const cancelRef = useRef() as MutableRefObject<any>;
	const isSelectorPredefined = typeof selector === "string";

	const [isLoading, setIsLoading] = useState(false);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [deleteId, setDeleteId] = useState<number | string | null>(null);
	const [defaultOptions, setDefaultOptions] = useState<IOption[]>([]);

	useEffect(() => {
		fetchOptions("").then(() => console.log("Fetch complete"));
	}, [selectorFilters, selector]);

	const handleCreate = async (
		inputValue: string,
		onChange: (event: any) => void,
	) => {
		setIsLoading(true);
		try {
			if (isSelectorPredefined) {
				const option = await createSelectOption(inputValue, selector);
				onChange(convertDataToSelectOption({ originalData: option }));
				await fetchOptions("");
			}
			if (localCreatable) {
				const option = { label: inputValue, value: inputValue };
				onChange(option);
				await fetchOptions("", [option]);
			}
		} catch (e: any) {
			toast({ title: e.message });
		} finally {
			setIsLoading(false);
		}
	};

	const handleDelete = async (id: number | string | null) => {
		setIsLoading(true);
		try {
			if (id) {
				isSelectorPredefined
					? await deleteSelectOption(selector, id)
					: onOptionDelete && (await onOptionDelete(id));
				onDeleteReject();
				await fetchOptions("");
			}
		} catch (e: any) {
			toast({ title: e.message });
		} finally {
			setIsLoading(false);
		}
	};

	const filterOptions = (value: string, options: IOption[]) =>
		options.filter(
			(option) =>
				!value ||
				option.label.toLocaleLowerCase().includes(value.toLocaleLowerCase()),
		);

	const mapOptions = (
		options: any[],
		mapKeys?: FormAsyncSelectProps["mappingKeys"],
	) =>
		options.map((option) => {
			const { labelKey: labelKeyProp, valueKey: valueKeyProp } = mapKeys || {};
			let label: string;
			let value: string | number;
			if (typeof labelKeyProp === "object") {
				label = labelKeyProp
					.map((key) => getNestedValue(option, key))
					.join(" ");
			} else {
				label = getNestedValue(option, labelKeyProp || "label");
			}
			if (typeof valueKeyProp === "object") {
				value = valueKeyProp
					.map((key) => getNestedValue(option, key))
					.join(" ");
			} else {
				value = getNestedValue(option, valueKeyProp || "id");
			}
			return {
				label,
				value,
				data: option,
			};
		});

	const fetchOptions = async (inputValue: string, localOptions?: IOption[]) => {
		const response = isSelectorPredefined
			? await getSelectOptions(selector)
			: //@ts-ignore
			  await selector(inputValue, selectorFilters);
		//@ts-ignore
		const options = [...response.items, ...(localOptions || [])];
		const mappedOptions = [
			...additionalOptions,
			...mapOptions(options, mappingKeys),
		];
		setDefaultOptions(mappedOptions);
		const filtered = filterOptions(inputValue, mappedOptions);
		return filtered;
	};

	const loadOptionsDebounced = useCallback(
		debounce((inputValue: string, callback: (options: any) => void) => {
			fetchOptions(inputValue).then((options) => callback(options));
		}, 500),
		[],
	);

	const onOptionDeleteClick = (option: IOption) => {
		setDeleteId(option.value);
		onOpen();
	};

	const onDeleteReject = () => {
		setDeleteId(null);
		onClose();
	};

	const Option = (props: any) => (
		<chakraComponents.Option {...props}>
			<HStack justify={"space-between"} w={"100%"}>
				<Box>{props.children}</Box>
				{!props.data.__isNew__ &&
					authData?.role?.value === UserTypeEnum.Admin && (
						<IconButton
							variant="transparent"
							onClick={() => onOptionDeleteClick(props.data)}
							aria-label="open menu"
							right={0}
							icon={<FiTrash2 />}
						/>
					)}
			</HStack>
		</chakraComponents.Option>
	);

	const OptionDefault = (props: any) => (
		<chakraComponents.Option {...props}>
			{props.children}
		</chakraComponents.Option>
	);

	return (
		<>
			<Controller
				control={control}
				name={name}
				render={({
					field: { onChange, onBlur, value, name, ref },
					fieldState: { invalid, error, isDirty },
				}) => (
					<FormControl isInvalid={invalid}>
						{!!label && (
							<FormLabel htmlFor={name}>
								<Heading size={"sm"}>
									{label}
									{required && (
										<span style={{ marginLeft: 5, color: "red" }}>*</span>
									)}
								</Heading>
							</FormLabel>
						)}
						{creatable || localCreatable ? (
							<AsyncCreatableSelect
								ref={ref}
								id={name}
								name={name}
								placeholder={placeholder}
								onChange={onChange}
								onBlur={onBlur}
								value={value}
								defaultOptions={defaultOptions}
								isDisabled={disabled || isLoading}
								isLoading={isLoading}
								onCreateOption={(val) => handleCreate(val, onChange)}
								formatCreateLabel={(value) => <Text mb={0}>Use "{value}"</Text>}
								loadOptions={loadOptionsDebounced}
								components={{ Option }}
								isMulti={isMulti}
								isClearable={isClearable}
								chakraStyles={styles}
								isRequired={required}
							/>
						) : (
							<AsyncSelect
								ref={ref}
								id={name}
								name={name}
								placeholder={placeholder}
								onChange={onChange}
								onBlur={onBlur}
								value={value}
								defaultOptions={defaultOptionsProps || defaultOptions || true}
								isDisabled={disabled || isLoading}
								isLoading={isLoading}
								loadOptions={loadOptionsDebounced}
								components={{ Option: optionComponent || OptionDefault }}
								isMulti={isMulti}
								isClearable={isClearable}
								chakraStyles={styles}
								isRequired={required}
							/>
						)}
						{required && (
							<input
								tabIndex={-1}
								autoComplete="off"
								style={{
									opacity: 0,
									width: "100%",
									// height: 0,
									position: "absolute",
								}}
								value={value}
								required={required}
							/>
						)}
						{shouldShowUnsavedWarning && isDirty && <DirtyFieldHelperText />}
						{!!error && <FormErrorMessage>{error?.message}</FormErrorMessage>}
					</FormControl>
				)}
			/>
			<AlertDialog
				motionPreset="slideInBottom"
				leastDestructiveRef={cancelRef}
				onClose={onDeleteReject}
				isOpen={isOpen}
				isCentered
			>
				<AlertDialogOverlay />

				<AlertDialogContent>
					<AlertDialogHeader>
						{"Do you really wish to delete this option?"}
					</AlertDialogHeader>
					<AlertDialogCloseButton />
					<AlertDialogFooter>
						<Button ref={cancelRef} onClick={onDeleteReject}>
							{"No"}
						</Button>
						<Button
							colorScheme="red"
							ml={3}
							onClick={() => handleDelete(deleteId)}
						>
							{"Yes"}
						</Button>
					</AlertDialogFooter>
				</AlertDialogContent>
			</AlertDialog>
		</>
	);
};

export default FormAsyncSelect;
