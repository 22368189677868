import { Obj } from "@interfaces/types";

/**
 *
 * @param obj object with nested objects eg {item: {item1: {item2: "value"}}}
 * @param keyString dot separated keys for each nesting level eg "item.item1.item2"
 */
const getNestedValue = (obj?: Obj, keyString?: string): any => {
	if (!obj || !keyString) {
		return;
	}
	const keysArr = keyString.split(".");
	return keysArr.reduce(
		(currentState, currentKey) => currentState?.[currentKey],
		obj,
	);
};

export default getNestedValue;
