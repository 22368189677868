import { axiosClient } from "@definitions/api";
import axios, { AxiosResponse } from "axios";
import { ListResponse, Obj } from "@interfaces/types";
import {
	ICountry,
	ICountryFull,
	IGeolocation,
	IGetGeolocationData,
} from "@interfaces/api/geocoding.types";
import { IOption } from "@components/form/form-async-select/form-async-select";
import { ISelect } from "@interfaces/api/select-options.types";
import { convertToSearchParams } from "@utils/convert-to-url-params";

export const getPositionInfo = async (
	data: IGetGeolocationData,
): Promise<ListResponse<IGeolocation>> => {
	try {
		const { lat, lng, phrase } = data;
		const searchParam = phrase || `${lat}/${lng}`;
		const axios = axiosClient();
		const resp: AxiosResponse<ListResponse<IGeolocation>> = await axios.get(
			encodeURI(`Geocoding/${searchParam}`),
		);
		return resp.data;
	} catch (err: any) {
		throw err.response;
	}
};

export const getCountries = async (
	input?: string,
): Promise<ListResponse<ICountry>> => {
	try {
		const axios = axiosClient();
		const resp: AxiosResponse<ListResponse<ICountry>> = await axios.get(
			`Countries/${"all"}`,
		);
		return resp.data;
	} catch (err: any) {
		throw err.response;
	}
};

export const getCities = async (
	input?: string,
	filters?: Obj,
): Promise<ListResponse<IOption>> => {
	try {
		const axios = axiosClient();
		const resp: AxiosResponse<ListResponse<IOption>> = await axios.get(
			`City/List`,
			{ params: convertToSearchParams(filters) },
		);
		return resp.data;
	} catch (err: any) {
		throw err.response;
	}
};

export const deleteCity = async (
	id: number | string,
): Promise<ListResponse<IOption>> => {
	try {
		const axios = axiosClient();
		const resp: AxiosResponse<ListResponse<IOption>> = await axios.delete(
			`City?id=${id}`,
		);
		return resp.data;
	} catch (err: any) {
		throw err.response;
	}
};
