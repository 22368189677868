import { IOption } from "@components/form/form-async-select/form-async-select";
import { Obj } from "@interfaces/types";
import getNestedValue from "@utils/get-nested-value";

const convertDataToSelectOption = (data: {
	labelKey?: string | string[];
	valueKey?: string | string[];
	separator?: string;
	originalData?: Obj;
}): IOption => {
	const {
		labelKey = "label",
		valueKey = "value",
		originalData,
		separator = " ",
	} = data;
	let label: string;
	let value: string | number;
	if (typeof labelKey === "object") {
		label = labelKey
			.map((key) => getNestedValue(originalData, key))
			.join(separator);
	} else {
		label = getNestedValue(originalData, labelKey || "label");
	}
	if (typeof valueKey === "object") {
		value = valueKey
			.map((key) => getNestedValue(originalData, key))
			.join(separator);
	} else {
		value = getNestedValue(originalData, valueKey || "id");
	}
	return {
		label,
		value,
		data: originalData,
	};
};

export default convertDataToSelectOption;
