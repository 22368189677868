import { axiosClient } from "@definitions/api";
import { AxiosResponse } from "axios";
import { ListResponse } from "@interfaces/types";
import { ISelect } from "@interfaces/api/select-options.types";
import { SelectValueTypes } from "@definitions/select-keys";

export const createSelectOption = async (
	name: string,
	key: SelectValueTypes,
): Promise<ISelect> => {
	try {
		const axios = axiosClient();
		const resp: AxiosResponse<ISelect> = await axios.post(`Select/${key}`, {
			name,
		});
		return resp.data;
	} catch (err: any) {
		throw err.response;
	}
};

export const getSelectOptions = async (
	key: SelectValueTypes,
): Promise<ListResponse<ISelect>> => {
	try {
		const axios = axiosClient();
		const resp: AxiosResponse<ListResponse<ISelect>> = await axios.get(
			`Select/${key}/List`,
		);
		return resp.data;
	} catch (err: any) {
		throw err.response;
	}
};

export const deleteSelectOption = async (
	key: SelectValueTypes,
	id: number | string,
): Promise<void> => {
	try {
		const axios = axiosClient();
		await axios.delete(`Select/${key}/${id}`);
	} catch (err: any) {
		throw err.response;
	}
};
